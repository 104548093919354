import HomePageComponent from '../components/HomePageComponent';
import { BackendBaseURL } from '../constants/appConstants/Urls';
import { localeString } from '../utils/ternaryConditionUtil';
import { handleContentAPIResponse } from '../utils/errors';
import { getContent } from '../apiCall/content';
import { useEffect, useState } from 'react';
import { LocaleVariables } from '../constants/appConstants/Locales';
import getQueryVariable from '../utils/queryVariable';


const BASE_HOST = BackendBaseURL.BACKEND_BASE_URL;

export async function getStaticProps(context: any) {

  const response = await getContent(`${BASE_HOST}/api/content/${process.env.NODE_ENV === 'production' ? 'homepage-no-cache' : 'homepage'}?populate=deep&locale=${context.locale}`)
  const correctResponse = await handleContentAPIResponse(response);
  const dataForHomePage = await correctResponse.json()
  const locale = context?.locale;

  return {
    props: {
      dataForHomePage,
      locale: locale
    },
  }
}

const HomeStep1 = ({ dataForHomePage, locale }: { dataForHomePage: any, locale: any }) => {

  useEffect(() => {
    window.sessionStorage.setItem('utmParams', JSON.stringify({
      is_referral: getQueryVariable("is_referral", window.location.search.substring(1)),
      referral_source: getQueryVariable("referral_source", window.location.search.substring(1)),
      referral_code: getQueryVariable("referral_code", window.location.search.substring(1)),
      utmCampaign: getQueryVariable("utm_campaign", window.location.search.substring(1)),
      utmMedium: getQueryVariable("utm_medium", window.location.search.substring(1)),
      utmTerm: getQueryVariable("utm_term", window.location.search.substring(1)),
      utmSource: getQueryVariable("utm_source", window.location.search.substring(1))
    }))
  }, [])

  const locale_string = localeString(locale);
  return (
    <div className={locale_string === LocaleVariables.ads_da ? 'ads_style_all' : 'style_all'}>
      <HomePageComponent stepNumber={1} dataForHomePage={dataForHomePage} locale={locale_string} />
    </div>
  )

}

export default HomeStep1
